import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Image from 'components/atoms/Image';
import { BodyText, H0 } from 'components/atoms/Typography';
import { storyblokEditable } from '@storyblok/react';
import Button from 'components/atoms/Button';
import Link from 'next/link';
import HeightObserver from 'components/atoms/HeightObserver';
import breakpoints from 'styles/breakpoints';
import RevealElement from 'components/atoms/RevealElement';
import { newLineToBr } from 'helpers';

const HeroContainer = styled.div`
    background: rgba(0 0 0 / 0.25);
    display: grid;
    grid-template:
        calc(var(--header-height) + var(--section-spacing-inline)) 1fr var(--section-spacing-inline)
        / calc((100vw - var(--container-inner-width, 0px)) / 2)
        1fr calc((100vw - var(--container-inner-width, 0px)) / 2);
    overflow: hidden;
    min-height: 100vh;
    @supports (height: 100svh) {
        min-height: 100svh;
    }
    .hero__image {
        grid-area: 1 / 1 / span 3 / span 3;
    }
    .hero__content {
        grid-area: 2 / 2;
    }
    .hero__buttonWrapper {
        @media ${breakpoints.sm} {
            margin-top: ${({ blokUid }) =>
                `calc(var(--herov3-content-${blokUid}-height) - var(--herov3-button-${blokUid}-height))`};
        }
    }
`;

const Hero = ({ blok }) => {
    const positions = {
        mobile: {
            content: {
                topLeft: 'place-self-start text-start row-start-1',
                topCenter: 'self-start justify-self-center text-center row-start-1',
                topRight: 'self-start justify-self-end text-end row-start-1',
                middleLeft: 'self-center justify-self-start text-start row-start-2',
                middleCenter: 'place-self-center text-center row-start-2',
                middleRight: 'self-center justify-self-end text-end row-start-2',
                bottomLeft: 'self-end justify-self-start text-start row-start-3',
                bottomCenter: 'self-end justify-self-center text-center row-start-3',
                bottomRight: 'place-self-end text-end row-start-3',
            },
            innerContent: {
                topLeft: 'place-self-start text-start',
                topCenter: 'place-self-center text-center',
                topRight: 'place-self-end text-end',
                middleLeft: 'place-self-start text-start',
                middleCenter: 'place-self-center text-center',
                middleRight: 'place-self-end text-end',
                bottomLeft: 'place-self-start text-start',
                bottomCenter: 'place-self-center text-center',
                bottomRight: 'place-self-end text-end',
            },
            button: {
                topLeft: 'place-self-start text-start row-start-1',
                topCenter: 'self-start justify-self-center text-center row-start-1',
                topRight: 'self-start justify-self-end text-end row-start-1',
                middleLeft: 'self-center justify-self-start text-start row-start-2',
                middleCenter: 'place-self-center text-center row-start-2',
                middleRight: 'self-center justify-self-end text-end row-start-2',
                bottomLeft: 'self-end justify-self-start text-start row-start-3',
                bottomCenter: 'self-end justify-self-center text-center row-start-3',
                bottomRight: 'place-self-end text-end row-start-3',
            },
        },
        desktop: {
            content: {
                topLeft:
                    'xl:place-self-start xl:text-start xl:col-start-1 xl:row-start-1 xl:col-span-2',
                topCenter:
                    'xl:self-start xl:justify-self-center xl:text-center xl:col-start-2 xl:row-start-1 xl:col-span-3',
                topRight:
                    'xl:self-start xl:justify-self-end xl:text-end xl:col-start-4 xl:row-start-1 xl:col-span-2',
                middleLeft:
                    'xl:self-center xl:justify-self-start xl:text-start xl:col-start-1 xl:row-start-2 xl:col-span-2',
                middleCenter:
                    'xl:place-self-center xl:text-center xl:col-start-2 xl:row-start-2 xl:col-span-3',
                middleRight:
                    'xl:self-center xl:justify-self-end xl:text-end xl:col-start-4 xl:row-start-2 xl:col-span-2',
                bottomLeft:
                    'xl:self-end xl:justify-self-start xl:text-start xl:col-start-1 xl:row-start-3 xl:col-span-2',
                bottomCenter:
                    'xl:self-end xl:justify-self-center xl:text-center xl:col-start-2 xl:row-start-3 xl:col-span-3',
                bottomRight:
                    'xl:place-self-end xl:text-end xl:col-start-4 xl:row-start-3 xl:col-span-2',
            },
            innerContent: {
                topLeft: 'xl:place-self-start xl:text-start',
                topCenter: 'xl:place-self-center xl:text-center',
                topRight: 'xl:place-self-end xl:text-end',
                middleLeft: 'xl:place-self-start xl:text-start',
                middleCenter: 'xl:place-self-center xl:text-center',
                middleRight: 'xl:place-self-end xl:text-end',
                bottomLeft: 'xl:place-self-start xl:text-start',
                bottomCenter: 'xl:place-self-center xl:text-center',
                bottomRight: 'xl:place-self-end xl:text-end',
            },
            button: {
                topLeft: 'xl:place-self-start xl:text-start xl:col-start-1 xl:row-start-1',
                topCenter:
                    'xl:self-start xl:justify-self-center xl:text-center xl:col-start-3 xl:row-start-1',
                topRight:
                    'xl:self-start xl:justify-self-end xl:text-end xl:col-start-5 xl:row-start-1',
                middleLeft:
                    'xl:self-center xl:justify-self-start xl:text-start xl:col-start-1 xl:row-start-2',
                middleCenter: 'xl:place-self-center xl:text-center xl:col-start-3 xl:row-start-2',
                middleRight:
                    'xl:self-center xl:justify-self-end xl:text-end xl:col-start-5 xl:row-start-2',
                bottomLeft:
                    'xl:self-end xl:justify-self-start xl:text-start xl:col-start-1 xl:row-start-3',
                bottomCenter:
                    'xl:self-end xl:justify-self-center xl:text-center xl:col-start-3 xl:row-start-3',
                bottomRight: 'xl:place-self-end xl:text-end xl:col-start-5 xl:row-start-3',
            },
        },
    };

    const isSameRow = (position1, position2) => {
        // Define the rows and their corresponding positions
        const rows = {
            top: ['topLeft', 'topCenter', 'topRight'],
            middle: ['middleLeft', 'middleCenter', 'middleRight'],
            bottom: ['bottomLeft', 'bottomCenter', 'bottomRight'],
        };

        // Check which row each position belongs to
        let row1 = null;
        let row2 = null;

        for (let row in rows) {
            if (rows[row].includes(position1)) row1 = row;
            if (rows[row].includes(position2)) row2 = row;
        }

        // If both positions are in the same row, return true
        return row1 === row2;
    };

    return (
        <HeroContainer
            as={blok?.enableSectionClickable ? 'a' : 'div'}
            {...(blok?.enableSectionClickable && blok?.buttonLink && { href: blok.buttonLink })}
            {...storyblokEditable(blok)}
            {...(blok?.allowTransparentHeader && { 'data-allow-transparent-header': 'true' })}
            blokUid={blok._uid}
            className="relative"
        >
            <div
                className={`hero__content grid grid-cols-3 xl:grid-cols-5 relative z-10 ${
                    isSameRow(blok?.desktopButtonPosition, blok?.desktopContentPosition)
                        ? 'xl:grid-rows-1'
                        : 'xl:grid-rows-3'
                }`}
            >
                <div
                    className={`col-span-full max-w-3xl w-full flex flex-col gap-6 xl:gap-9 ${
                        isSameRow(blok?.desktopButtonPosition, blok?.desktopContentPosition)
                            ? 'xl:!row-start-1'
                            : ''
                    } ${positions.mobile.content[blok?.mobileContentPosition || 'middleCenter']} ${
                        positions.desktop.content[blok?.desktopContentPosition || 'middleCenter']
                    }`}
                >
                    <HeightObserver
                        variable={`herov3-content-${blok._uid}`}
                        className="flex flex-col gap-5"
                    >
                        {blok.heading && (
                            <RevealElement start="top 100%">
                                <H0
                                    color="#fff"
                                    fontSize={blok.headingFontSize || '96px'}
                                    fontSizeMobile={blok.mobileHeadingFontSize || '56px'}
                                >
                                    {blok.heading}
                                </H0>
                            </RevealElement>
                        )}
                        {blok.text && (
                            <RevealElement start="top 100%" delay={0.1}>
                                <BodyText
                                    font="heading"
                                    color="#fff"
                                    fontSize="1.5rem"
                                    lineHeight="1.2"
                                    letterSpacing="-0.01em"
                                    fontSizeMobile="1.25rem"
                                    dangerouslySetInnerHTML={{
                                        __html: newLineToBr({ string: blok.text }),
                                    }}
                                />
                            </RevealElement>
                        )}
                    </HeightObserver>
                    {blok.buttonText && (
                        <div
                            className={`${
                                isSameRow(blok?.mobileButtonPosition, blok?.mobileContentPosition)
                                    ? ''
                                    : 'hidden'
                            } ${
                                blok?.desktopButtonPosition === blok?.desktopContentPosition
                                    ? 'xl:block'
                                    : 'xl:hidden'
                            } ${
                                positions.mobile.innerContent[
                                    blok?.mobileButtonPosition || 'middleCenter'
                                ]
                            } ${
                                positions.desktop.innerContent[
                                    blok?.desktopButtonPosition || 'middleCenter'
                                ]
                            }`}
                        >
                            <RevealElement start="top 100%" delay={0.2}>
                                <HeightObserver
                                    variable={`herov3-button-inner-${blok._uid}`}
                                    className="hero__buttonInnerWrapper"
                                >
                                    <Button
                                        as={blok?.enableSectionClickable ? 'span' : Link}
                                        inverted={true}
                                        {...(!blok?.enableSectionClickable && {
                                            href: blok.buttonLink || '/',
                                        })}
                                        label={blok.buttonText}
                                        display="inline-flex"
                                    />
                                </HeightObserver>
                            </RevealElement>
                        </div>
                    )}
                </div>
                {blok.buttonText && (
                    <div
                        className={`col-span-full xl:col-span-1 max-w-3xl w-full ${
                            isSameRow(blok?.desktopButtonPosition, blok?.desktopContentPosition)
                                ? 'xl:!row-start-1'
                                : ''
                        } ${
                            isSameRow(blok?.mobileButtonPosition, blok?.mobileContentPosition)
                                ? 'hidden'
                                : ''
                        } ${
                            blok?.desktopButtonPosition === blok?.desktopContentPosition
                                ? 'xl:hidden'
                                : 'xl:block'
                        } ${
                            positions.mobile.button[blok?.mobileButtonPosition || 'middleCenter']
                        } ${
                            positions.desktop.button[blok?.desktopButtonPosition || 'middleCenter']
                        }`}
                    >
                        <RevealElement start="top 100%" delay={0.2}>
                            <HeightObserver
                                variable={`herov3-button-${blok._uid}`}
                                className="hero__buttonWrapper"
                            >
                                <Button
                                    as={blok?.enableSectionClickable ? 'span' : Link}
                                    inverted={true}
                                    {...(!blok?.enableSectionClickable && {
                                        href: blok.buttonLink || '/',
                                    })}
                                    label={blok.buttonText}
                                    display="inline-flex"
                                />
                            </HeightObserver>
                        </RevealElement>
                    </div>
                )}
            </div>
            {blok?.image?.filename && (
                <>
                    <Image
                        className={`hero__image w-full h-full mix-blend-multiply ${
                            blok.mobileImage ? 'xl:block hidden' : ''
                        }`}
                        src={blok.image.filename}
                        priority={true}
                        sizes="(max-width: 480px) 90vw, 100vw"
                        alt={blok.image?.alt || blok.heading}
                        fill={true}
                    />
                    {blok?.mobileImage?.filename && (
                        <Image
                            className="hero__image w-full h-full mix-blend-multiply xl:hidden"
                            src={blok.mobileImage.filename}
                            priority={true}
                            sizes="100vw"
                            alt={blok.mobileImage?.alt || blok.heading}
                            fill={true}
                        />
                    )}
                </>
            )}
        </HeroContainer>
    );
};

Hero.propTypes = {
    blok: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Hero;
