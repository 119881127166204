import { throttle } from 'helpers';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Element = styled.div``;

const HeightObserver = ({ variable, children, as = 'div', ...attrs }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!variable) {
            return;
        }
        const updateHeight = throttle(() => {
            if (ref.current) {
                const height = ref.current.clientHeight;
                document.documentElement.style.setProperty(
                    `--${variable}-height`,
                    `${Math.round(height)}px`
                );
            }
        }, 100);

        if (window?.ResizeObserver) {
            const observer = new ResizeObserver(updateHeight);
            if (ref.current) observer.observe(ref.current);
            return () => {
                if (ref.current) observer.unobserve(ref.current);
            };
        } else {
            updateHeight(); // Fallback for browsers without ResizeObserver
        }
    }, [variable]);

    return (
        <Element as={as} ref={ref} {...attrs}>
            {children}
        </Element>
    );
};

HeightObserver.propTypes = {
    variable: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

export default HeightObserver;
